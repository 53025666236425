export const contactBarLinks = [
  {
    text: 'LinkedIn',
    url: 'https://www.linkedin.com/in/anthonyvolk'
  },
  {
    text: 'GitHub',
    url: 'https://www.github.com/anth-volk'
  },
  {
    text: 'Email',
    url: 'mailto:anth.volk@gmail.com'
  },
];