export const shades = {
	dark: '#121212',
	light: '#ccccce',
};

export const colors = {
	red: '#be666f',
	blue: '#67989e',
	yellow: '#dec467'
};

