export const navLinks = [
  /*
	{
		text: 'about',
		url: '#about'
	},
  */
	{
		text: 'experience',
		url: '#experience'
	},
  /*
  {
    text: 'projects',
    url: '#projects'
  },
	{
		text: 'contact',
		url: '#contact'
	}
  */
];